.neutral {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
}

.unwell {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
}

.well {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
}

.face {
  fill: #ffb536;
  transition: fill 0.3s ease-in-out;

  &.flush {
    fill: #fc7e7d;
  }
}
